<template>
    <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <!-- <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        /> -->
      </div>
      <div>
        <v-btn
          @click="getPrices"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createPrice"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Price
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="prices"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item.type="{ item }">
        <v-chip
          outlined
          small
          :color="translatorsType[item.type].color"
        >
          {{ translatorsType[item.type].value }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-progress-circular
          v-if="loadingDestroy && currentPrice.id === item.id"
          size="24"
          indeterminate
          color="primary"
        />
        <v-menu v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item link @click="destroyPrice(item)">
              <v-list-item-title>
                <v-icon left>mdi-trash-can</v-icon>
                Eliminar
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <!-- <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div> -->
    <PriceCreate
      ref="priceCreate"
      @onAddPrice="onAddPrice"
    />
  </div>
</template>

<script>
import PriceServices from '@/store/services/general/PriceServices';
import PriceCreate from './PriceCreate';
import { mapGetters } from 'vuex';
import { TRANSLATORS_TYPE } from '@/constants';
export default {
  components: {
    PriceCreate,
  },
  data() {
    return {
      loading: false,
      loadingDestroy: false,
      prices: [],
      currentPrice: {
        id: -1,
      },

      translatorsType: TRANSLATORS_TYPE,
    };
  },
  created() {
    this.getPrices();
  },
  methods: {
    async getPrices() {
      try {
        this.loading = true;
        const params = {
          order_by: 'name',
          order_direction: 'ASC',
        };
        const response = await PriceServices.index(this.user.archon.id, params);
        this.prices = response.data.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createPrice() {
      this.$refs.priceCreate.dialog = true;
    },
    onAddPrice(data) {
      this.prices.push(data.price);
      this.$notify({
        group: 'petition',
        title: 'Precio',
        type: 'success',
        text: `${data.price.name} fue agregado exitosamente`,
      });
    },
    async destroyPrice(item) {
      try {
        this.loadingDestroy = true;
        this.currentPrice.id = item.id;
        await PriceServices.destroy(this.user.archon.id, item.id);
        this.$notify({
          group: 'petition',
          title: 'Precio',
          type: 'success',
          text: `${item.name} fue eliminado`,
        });
        this.loadingDestroy = false;

        const index = this.prices.findIndex(price => price.id === item.id);
        this.prices.splice(index, 1);
      } catch (error) {
        this.loadingDestroy = false;
        console.log(error);  
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Nombre',
          sortable: false,
          value: 'name',
        },
        { text: 'Tipo', value: 'type', sortable: false },
      ];
    },
  },
};
</script>