<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
  >
    <v-card>
      <Loader :loading="loading" absolute />
      <v-card-title>
        <v-icon left>mdi-currency-usd</v-icon>
        Nuevo precio
      </v-card-title>
      <v-card-text>
        <v-form ref="form" onsubmit="return false;">
          <v-text-field
            v-model="price.name"
            dense
            label="Nombre"
            :rules="[isRequired]"
            :error-messages="errorName"
          />
          <v-select
            v-model="price.type"
            dense
            label="Nombre"
            :items="types"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="grey lighten-1"
          small
          @click="close"
        >
          Cerrar
        </v-btn>
        <v-btn
          color="success"
          small
          @click="storePrice"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PriceServices from '@/store/services/general/PriceServices';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      loading: false,
      dialog: false,

      // price
      price: {
        name: '',
        type: 'SELL',
        code: 'SELL',
        currency_id: 1,
      },

      types: ['SELL', 'COST', 'BUY'],

      errorName: '',

      // rules
      isRequired: value => !!value || 'Campo requerido',
    };
  },
  methods: {
    async storePrice() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          this.errorName = '';
          const response = await PriceServices.store(this.user.archon.id, this.price);
          this.$emit('onAddPrice', {
            price: response.data,
          });
          this.dialog = false;
          setTimeout(() => {
            this.resetData();
          }, 500);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          if (error.response.status === 422) {
            this.errorName = error.response.data.errors?.name[0];
          }
        }
      }
    },
    resetData() {
      this.price = {
        name: '',
        type: 'SELL',
        code: 'SELL',
        currency_id: 1,
      };
      this.$refs.form.resetValidation();
    },
    close() {
      this.dialog = false
      this.resetData()
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
}
</script>